import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import HomePageTemplate from '../components/HomePageTemplate'
import Layout from '../components/Layout'
import config from '../../config'
import SE0 from '../components/SEO'

const HomePage = (props) => {
  const { data: { markdownRemark: { frontmatter: { title, meta_title, meta_description, heading, keywords } } } } = props
  for (let i=1;i<keywords.length;i++){
    if (keywords[i].charAt(0)!==' '){
    keywords[i] = ' ' + keywords[i];
    }
    //console.log(keywords[i]);
  }
  //console.log(String(keywords));

  return (
    <Layout>
    <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
    <script type='application/ld+json'>
    {JSON.stringify({
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [{
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": "https://artopen.co"
      }]
    })}
    </script>
    </Helmet>
    <SE0
      title={title}
      meta_title={meta_title}
      meta_desc={meta_description}
      keywords={keywords}
      slug="/"
      cover="/img/home.svg"
      siteTitleAlt={config.siteTitleAlt}
      userName={config.userName}
      siteTitle={config.siteTitle}
      siteUrl={config.siteUrl}
      siteFBAppID={config.siteFBAppID}
      userTwitter={config.userTwitter}
      pathPrefix={config.pathPrefix}
    />
      <HomePageTemplate
        title={title}
        meta_title={meta_title}
        meta_description={meta_description}
        heading={heading}
        keywords={keywords}
      />

    </Layout>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default HomePage

export const pageQuery = graphql`
  query IndexPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        meta_title
        meta_description
        heading
        keywords
      }
    }
  }
`

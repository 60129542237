import loadable from '@loadable/component'
import React from 'react'
import { useEffect } from "react";
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Offerings from '../Offerings'
import PropTypes from 'prop-types'
import PortfolioRoll from '../PortfolioRoll'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

const ModalCall = loadable(() => import('../ModalCall'))
const ContactCall = loadable(() => import('../ContactCall'))
const NewsCall = loadable(() => import('../NewsCall'))
const BlogRoll = loadable(() => import('../BlogRoll'))
const OfferRoll = loadable(() => import('../OfferRoll'))

const HomePageTemplate = (props) => {
  const { title, meta_title, meta_description, heading, keywords } = props

  useEffect(() => {

    var lazyBackgrounds = [].slice.call(
      document.querySelectorAll(".lazy-background")
    );

    if (
      "IntersectionObserver" in window &&
      "IntersectionObserverEntry" in window &&
      "intersectionRatio" in window.IntersectionObserverEntry.prototype
    ) {
      let lazyBackgroundObserver = new IntersectionObserver(function (
        entries,
        observer
      ) {
        entries.forEach(function (entry) {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            lazyBackgroundObserver.unobserve(entry.target);
            countDown();
            console.log('enter viewport');
          }
        });
      });
      lazyBackgrounds.forEach(function (lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
      });
    }
  ;

  }, []);

  return (
    <>
      <Helmet htmlAttributes={{ lang : 'en-en', dir:'ltr' }}>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
        <meta name='keywords' content={keywords} />
      </Helmet>
      {/*<div className='akcja-sprzatnie' style={{backgroundSize:'cover',backgroundImage:'url(/img/akcja-sprzatanie-3.svg)',backgroundPosition:'bottom'}}>
      <p style={{color:'white',backgroundColor:'#23C495'}}><b>Akcja sprzątanie</b><br />
      <sub style={{color:'white'}}>Otwarci na środowisko</sub></p>
      <br />
      <Link to="/akcja-sprzatanie-vol-3/" className="button-green" style={{backgroundColor:'#111'}}>Zapisz się</Link>
      </div>*/}
      <section id="mainhero" className='hero is-medium' style={{minHeight:'70%',backgroundColor:'#21674E', backgroundSize:'contain', backgroundImage:'url(/img/home.svg)',backgroundRepeat:'no-repeat',backgroundPosition:'center left',backgroundAttachment: `fixed`,}}>
        <div className='hero-body' style={{padding:'8rem 1.5rem'}}>
        <div className='container section'>
            <div className='columns section'>
              <div className='column is-5 is-offset-1'>
                <div className='' id="mainintro" style={{color:'white'}}>
                  <Link to="/quote-art-open/"><img alt="Art Open advertising agency" title="Art Open advertising agency" id="logointro"  src="/img/ArtOpen-white.svg" style={{marginLeft:'-25px'}} width="400px"/></Link>
                  <h1 style={{color:'white'}} className='title'>
                    {title}
                  </h1>
                  <h2 style={{color:'white'}} className='subtitle'>
                    {heading}
                  </h2>
                  <div className="topUi">
                  <Link className='button-invert' to='/offer/graphic-design/'> graphic design &nbsp;&nbsp;
                  <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="graphic design" title="graphic design" src='/img/pencil-alt.svg'/>
                   </Link>&nbsp;&nbsp;
                  <Link className='button-invert' to='/offer/ecology/'> ecology &nbsp;&nbsp;
                  <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="eco" title="ecology" src='/img/leaf.svg'/>
                   </Link>&nbsp;&nbsp;
                   <Link className='button-invert' to='/offer/gadgets/'> gadgets &nbsp;&nbsp;
                   <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="electronic gadgets" title="promotional gadgets" src='/img/lightbulb.svg'/>
                    </Link>&nbsp;&nbsp;
                    <br /><br />
                   <Link className='button-invert' to='/offer/web-pages/'> websites &nbsp;&nbsp;
                   <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="websites and online stores" title="websites and online shops" src='/img/code.svg'/>
                    </Link>&nbsp;&nbsp;
                    <Link className='button-invert' to='/offer/vr/'> VR &nbsp;&nbsp;
                    <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="VR / AR" title="VR / AR" src='/img/vr-cardboard.svg'/>
                     </Link>&nbsp;&nbsp;
                     <Link className='button-invert' to='/offer/branding/'> branding &nbsp;&nbsp;
                     <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="branding" title="branding" src='/img/pencil-ruler.svg'/>
                      </Link>&nbsp;&nbsp;
                        <br /><br />
                        <Link className='button-invert' to='/offer/book-calendars/'> book calendars &nbsp;&nbsp;
                        <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="book calendars" title="book calendars" src='/img/book.svg'/>
                         </Link>&nbsp;&nbsp;
                         <Link className='button-invert' to='/offer/company-calendars/'> business calendars &nbsp;&nbsp;
                         <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="business calendars" title="business calendars" src='/img/calendar-alt.svg'/>
                          </Link>&nbsp;&nbsp;
                          </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


    {/*  <section className='hero is-primary is-bold'>
        <div className='hero-body' style={{backgroundColor:'#181818',marginTop:'0%'}}>
          <div className='container' style={{marginLeft:'auto',marginRight:'auto'}}>
            <div className='columns' style={{textAlign:'center'}}>
              <div className='column ofert'>
              <h3 style={{textAlign:'left'}}><strong style={{color:'white', fontSize:'20px'}}><Link to="/quote-art-open/"><b style={{color:'#00d1b2'}}>Protection product</b> range</Link></strong></h3>
              </div>


              <div className='column columns covidoffer' style={{marginTop:'20px',minWidth:'60%'}}>
              <Link to="/offer/covid-19/#masks" className='column offer' style={{margin:'10px',borderRadius:'15px'}}>
                <div >
                <img alt="surgical masks, cotton masks, logo masks, covid-19" title="surgical masks, cotton masks, masks with logo" width="40px"  style={{verticalAlign:'middle'}} src='/img/maseczki.svg'/>
                <br /><br />
                <h3 style={{color:'white'}}>masks</h3>
                </div>
              </Link>
              <Link to="/offer/covid-19/#protective-visor" className='column offer' style={{margin:'10px',borderRadius:'15px'}}>
                <div >
                <img alt="covid-19 protective visors" width="40px"  style={{verticalAlign:'middle'}} title="visors" src='/img/przylbice.svg'/>
                <br /><br />
                <h3 style={{color:'white'}}>visors</h3>
                </div>
              </Link>
              <Link to="/offer/covid-19/#antibacterial-products" className='column offer' style={{margin:'10px',borderRadius:'15px'}}>
                <div>
                <img alt="disinfection, antimicrobial gels, covid-19" width="40px" title="disinfection, antimicrobial gels, covid-19" style={{verticalAlign:'middle'}} src='/img/dezynfekcja.svg'/>
                <br /><br />
                <h3 style={{color:'white'}}>disinfection</h3>
                </div>
              </Link>
              </div>
              <div className='column'>
              <br />
              <Link className='button-white' to='/offer/covid-19/' ><b>check it out</b> &nbsp;&nbsp;
              <img width="20px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="covid-19" title="covid-19" src='/img/viruses.svg'/>
              </Link>
              </div>
            </div>
            <br />
            <div>
            <h3> <b>Art Open activities during <Link className="inline-link" to="/offer/covid-19/">COVID-19</Link> outbreak state</b></h3> <br />
            <h4>In connection with the introduction of the  <Link className="inline-link" to="/offer/covid-19/">COVID-19</Link> epidemic, we would like to inform you that the Art Open company continues its activities without interruption. We accept <Link className="inline-link" to="/quote-art-open/">orders</Link> on an ongoing basis and carry out all deliveries of our products to customers. All <Link className="inline-link" to="/offer/web-pages/">administrative</Link> and <Link className="inline-link" to="/offer/graphic-design/">graphic </Link> works are continued without significant changes in the process.</h4>
            </div>
          </div>
        </div>
      </section> */}


      <div style={{marginLeft:'auto',marginRight:'auto',textAlign:'center'}}>
      <br /><br />
      <img loading="lazy" src='/img/oferta-home.svg' style={{width:'70%'}} title="offer" alt='offer' />
      <h4 className='title'> Explore our <b><Link to="/offer/"  style={{color:'#23C495'}}>offer:</Link></b> </h4>
      <h5 className='subtitle' style={{padding:'10px'}}> See what we can do for you. </h5>
      <br />
      <OfferRoll />
      </div>


      <br />
      <br />
      <br />
      <ModalCall />


      <div style={{marginLeft:'auto',marginRight:'auto',marginTop:'50px'}}>
        <div style={{textAlign:'left',marginTop:'100px'}}>
        {/* TODO: Grafika 1*/}
        <img alt="Art Open Wrocław Portfolio" title="Art Open portfolio" loading="lazy" src='/img/portfolio2.svg' width='90%'/>

        <br /><br />
        <h4 className='title' style={{textAlign:'center',marginLeft:'15px',marginRight:'15px'}}><Link to="/portfolio/" style={{color:'#23C495'}}><b> Portfolio</b> </Link></h4>
        <h5 className='subtitle' style={{marginBottom:'20px',textAlign:'center',marginLeft:'auto',marginRight:'auto',maxWidth:'90%'}}> Take a look at our realizations. </h5>
        </div>

        <PortfolioRoll />
        < br/>
        <div style={{textAlign:'center'}}>
          <Link className="button-green" style={{fontSize:'18px'}} to="/portfolio/"> See our projects <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
        </div>
      </div>


      <div style={{marginTop:'5%',marginLeft:'auto',marginRight:'auto',marginBottom:'5%',textAlign:'center'}}>
      <div style={{textAlign:'right',marginTop:'0px',marginLeft:'50px'}}>

      {/*Grafika Blog*/}
      <img loading="lazy" alt="Art Open Wrocław Blog" title="Blog Art Open Wrocław" src='/img/blog.svg' width='90%' />

      </div>

      <br />
      <h4 className='title'> <Link to="/blog/" style={{color:'#23C495'}}><b>Blog</b></Link> </h4>
      <h5 className='subtitle'> We invite you to read more. </h5>
      <br />

      <BlogRoll />


      < br/>< br/>

          <Link className="button-green" style={{fontSize:'18px'}} to="/blog/"> See all entries <img width="12px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </Link>
      < br/>< br/>
      </div>

      <section className='hero' style={{marginTop:'-7px'}}>
        <NewsCall />
      </section>

      <section >

          <div style={{marginTop:'0px',marginBottom:'-7px'}}>


            <div className='columns' style={{margin:'0px',marginTop:'0px'}}>

              <div className='column halfsize' style={{marginLeft:'50px',marginRight:'50px'}}>
                <br />
                <h5 className='has-text-weight-semibold is-size-2'><Link className="inline-link" to="/offer/ecology/"><b>Ecological </b></Link> products</h5>
                <h3 className='subtitle'> What <Link className="inline-link" to="/about-us/#wyroznia-nas">sets us apart?</Link> </h3>

                <h4>One of the extremely important activities in our company are the ones supporting the  <Link className="inline-link" to='/blog/akcja-sprzatanie-otwarci-na-srodowisko/'>protection of the natural environment</Link> by offering <Link className="inline-link" to="/quote-art-open/#ecology">ecological and biodegradable products, such as stone or grass paper</Link>. We want to make our small contribution to the global pro-ecological movement.
                <br /><br />
                All the <Link className="inline-link" to="/offer/ecology/">products you can find in the ecological offer are environmentally friendly</Link>, durable and aesthetic. Giving up non-recyclable products can be the first, very important step for you to achieve an <Link className="inline-link" to="/blog/why-to-be-eco-friendly/">eco-friendly</Link> management style.</h4>
                <br />
                <br />
                <Link className='button-green' to='/offer/ecology/'> ecology &nbsp;&nbsp;
                <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}} title="ecology" alt="ecology" src='/img/leaf.svg'/>
                 </Link>
                <br />
                <br />
              </div>


              <div className='column' style={{marginRight:'0px',minWidth:'50%',textAlign:'right',padding:'0px',minWidth:'50%'}}>
              <img loading="lazy" title="ecological gadgets" src='/img/ekologia.jpg' alt="organic products" width='100%' />
              </div>

            </div>


        </div>
      </section>


      <section className='is-primary '>

          <div style={{marginTop:'0px'}}>

            <div className='columns' style={{margin:'0px',marginTop:'0px'}}>

            <div className='column' style={{marginRight:'0px',padding:'0px',minWidth:'50%'}}>
            <img loading="lazy" src='/img/kreator-kalendarz.jpg' title="online calendar creator" alt="calendar wizard" width='100%' />
            </div>

              <div className='column halfsize' style={{marginLeft:'50px',marginRight:'50px',textAlign:'right'}}>
                <br />
                <h5 className='has-text-weight-semibold is-size-2'><b style={{color:'#23C495'}}> <Link className="inline-link" to="/calendar-creator-online/">Calendar</Link></b> Wizard</h5>
                <h3 className='subtitle'> Design a <Link className="inline-link" to="/calendar-creator-online/">calendar online</Link> </h3>
                <br />
                <h4>Prepare your original <Link className="inline-link" to="/offer/book-calendars/">calendar or notebook</Link>, design, which will be in line with individual preferences and needs. Using the calendar wizard, you are able to create your own dream project by selecting accessories, binding, colors, ribbons and refinements. <br /><br /><Link className="inline-link" to="/quote-art-open/#Bookcalendars"> Use our form</Link> to send your <Link to="/calendars-creator/" className="inline-link">exported configuration in a graphic file format</Link>, select accessories, paper type, pattern and color of the binding.</h4>
                <br />
                <br />
                <Link className='button-green' to='/calendars-creator/'> calendar wizard &nbsp;&nbsp;
                    <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="calendars" src='/img/calendar-alt.svg'/>
                 </Link>
                <br />
                <br />
              </div>




            </div>
          </div>

      </section>


      <section className='hero-body is-primary is-bold is-medium lazy-background one' loading="lazy" style={{marginTop:'-7px',backgroundSize:'cover',backgroundRepeat:'no-repeat',backgroundPosition:'top',backgroundColor:'#23C495',backgroundAttachment: `fixed`,}}>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns timers'>

              <div className='column' style={{textAlign:'center'}}>
              <p style={{color:'white'}}>Years on the market</p>
              <h5 id='t1' className='is-size-2'>
              12
              </h5>
              </div>

              <div className='column' style={{textAlign:'center',borderLeft:'1px solid white'}}>
              <p style={{color:'white'}}>Regular customers</p>
              <h5 id='t2' className='is-size-2'>
              250
              </h5>
              </div>

              <div className='column' style={{textAlign:'center',borderLeft:'1px solid white'}}>
              <p style={{color:'white'}}>Graphic designs</p>
              <h5 id='t3' className='is-size-2'>
              5000
              </h5>
              </div>

              <div className='column' style={{textAlign:'center',borderLeft:'1px solid white'}}>
              <p style={{color:'white'}}>Completed orders</p>
              <h5 id='t4' className='is-size-2'>
              12500
              </h5>
              </div>

            </div>
          </div>
        </div>
      </section>

      <section className='is-bold is-medium'>
        <div className='hero-body'>
          <div className='container' >
          <div style={{textAlign:'center'}}>
          <img loading="lazy" id='rotate' alt="advertising agency Art Open" src="/img/logo-square.svg" width="120px" />
          <h5 className='has-text-weight-semibold is-size-2' >About <b style={{color:'#23C495'}}><Link className="inline-link" to="/about-us/">us:</Link></b></h5>
          </div>
          <br />
          <br />
            <div className='columns'>

              <div className='column' style={{marginLeft:'5%',marginRight:'5%'}}>
                <h3 className='title'> Advertising agency<br /> <b style={{color:'#23C495'}}>Art Open</b></h3>
                <br />
                <h4>Our team is a set of  <Link className="inline-link" to="/about-us/#zespol">people full of passion, creativity and commitment to each of the projects they take care of. </Link> For nearly a decade, together with you - our clients - we have been preparing various projects in the field of design activities, including unique <Link className="inline-link" to='/offer/book-calendars/'> book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'> company calendars</Link>, <Link className="inline-link" to='/offer/vr/'> virtual reality</Link>, <Link className="inline-link" to='/offer/branding/'>branding</Link>,<Link className="inline-link" to='/offer/graphic-design/'> promotion and graphic design</Link>, including <Link className="inline-link" to='/offer/web-pages/'>websites, e-commerce projects, applications </Link> and <Link className="inline-link" to='/offer/photography/'>session implementation, photos</Link> or <Link className="inline-link" to='/offer/filming/'>movies.</Link></h4>
                <br />
                <br />
                <Link className='button-green' to='/about-us/'> get to know our team &nbsp;&nbsp;
                  <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/>
                </Link>
              </div>


              <div className='column' style={{marginRight:'50px'}}>
              <img loading="lazy" alt="agencja reklamowa Art Open - O nas" src='/img/o-nas.svg' height='100%' />
              </div>

            </div>
          </div>
          <div style={{textAlign:'center',paddingTop:'50px'}}>
          <br />

          <h3 style={{fontSize:'18px'}}> Fill out our <b style={{color:'#23C495'}}><Link className="inline-link" to="/quote-art-open/">form</Link>:</b></h3>
          <br />
          <h4 style={{fontSize:'14px',marginLeft:'20%',marginRight:'20%'}}>Answer the questions and get free service pricing. In our offer you will find many services, such as <Link className="inline-link" to='/offer/graphic-design/'>graphic design</Link>, personalized <Link className="inline-link" to='/offer/book-calendars/'>book calendars</Link>, <Link className="inline-link" to='/offer/company-calendars/'>company calendars</Link>, <Link className="inline-link" to='/offer/ecology/'>ecological calendars</Link> <Link className="inline-link" to='/offer/gadgets/'>advertising gadgets</Link>, <Link className="inline-link" to='/offer/printouts/'>printouts</Link>, <Link className="inline-link" to='/offer/filming/'>advertising movies</Link> and <Link className="inline-link" to='/offer/photography/'>advertising photos</Link> as well as <Link className="inline-link" to='/offer/web-pages/'>website programming, including e-commerce, applications, </Link> or <Link className="inline-link" to='/offer/vr/'>AR and VR systems.</Link></h4>
          <br />
          <br />
          <Link className='button-green' to='/quote-art-open/'> get a quote &nbsp;&nbsp;
          <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="ecology" src='/img/leaf.svg'/>
           </Link>
          <br />
          </div>
        </div>
      </section>









      <div style={{marginTop:'5%',marginLeft:'7%',marginRight:'7%',marginBottom:'5%'}}>
      <h5 className='has-text-weight-semibold is-size-2'><b style={{color:'#23C495'}}>What customers say about us</b></h5>
      <br /><br />
      <div className="columns" style={{border:'1px solid lightgray',padding:'20px 20px 20px 20px',borderRadius:'25px',width:'100%'}}>

        <div style={{width:'50px',margin:'auto'}}>
          <div onClick={changeTestminus} className="button-green-no-zoom" style={{marginRight:'20px'}} >
            <img width="20px" alt="opinions" src='/img/angle-left.svg'/>
          </div>
        </div>

        <div style={{width:'90%',padding:'20px',textAlign:'center'}}>

        <img width="120px" loading="lazy"  style={{verticalAlign:'middle'}} title="rating" alt="rating" src='/img/rating-5.svg'/>
        <br /><br />
        <p id='txtchange' style={{textAlign:'left'}}>
        A company with ideas, creative graphic designers, unusual designs, ecological products, calendars with stone paper, waterproof, biodegradable. Gadgets made of wheat, bamboo, straw, coffee, interesting design, good communication, quick action and short turnaround. A company completely worth recommending
        </p>
        <br />
        <p id='autor' style={{color:'#00d1b2'}}>
        Marek Banach | KRAUSE Sp. z o.o.
        </p>

        <br />
        <br />
          <a className="button-green" target="_blank" rel='noopener norefferer'
          href="https://www.google.com/search?q=artopen+wroc%C5%82aw&oq=artopen+wroc%C5%82aw&aqs=chrome..69i57j0i22i30.2687j0j7&sourceid=chrome&ie=UTF-8#lrd=0x470fc21b332a9cd3:0x6f99075edfe8676,1,,,">
           See all opinions &nbsp;&nbsp; <img width="10px"  style={{verticalAlign:'middle', marginRight:'5px'}}   alt="arrow" src='/img/angle-right.svg'/> </a>
        </div>

         <div style={{width:'50px',margin:'auto'}}>
           <div onClick={changeTestplus} className="button-green-no-zoom" style={{marginLeft:'20px'}}>
             <img width="20px" alt="opinie" src='/img/angle-right.svg'/>
           </div>
         </div>
      </div>

      </div>

      <section  className='is-primary is-bold is-medium'>
        <div className='hero-body'>
          <div className='container'>
          <h5 className='has-text-weight-semibold is-size-2' style={{textAlign:'center'}}><b style={{color:'#23C495'}}>They trusted</b> us:</h5>
          <br />
          <AliceCarousel autoPlay infinite items={items} autoPlayInterval="3500">

          </AliceCarousel>
          <br />
          <h5 className='has-text-weight-semibold is-size-2' style={{textAlign:'center'}}><b style={{color:'#23C495'}}>Partners:</b></h5>
          <br />
          <AliceCarousel autoPlay infinite items={items2} autoPlayInterval="3500">

          </AliceCarousel>

          </div>
        </div>
      </section>

      <ContactCall />
    </>
  )
}


const handleDragStart = (e) => e.preventDefault();
{/*TODO:// when on wifi download +3 images*/}
const items = [

  [<img src="/img/logo-svg/betafence.svg" key="k1" alt="betafence" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/bieg-piastow.svg" key="k2" alt="bieg piastow" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/bny-mellon.svg" key="k3" alt="bny mellon" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/ccc.svg" alt="ccc" key="k4" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/careeflet.svg" key="k5" alt="careeflet" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/cargounit.svg" key="k6" alt="cargounit" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/chemeko.svg" key="k7" alt="chemeko" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/columbus.svg" key="k8" alt="columbus" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />],

  [<img src="/img/logo-svg/cooper.svg" key="k9" alt="cooper" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/credit.svg" key="k10" alt="credit" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/de-dietrich.svg" key="k11" alt="de-dietrich" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/delavalh.svg" key="k12" alt="de-laval" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/diehl.svg" key="k13" alt="diehl" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/donako.svg" key="k14" alt="donako" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/epore.svg" key="k15" alt="epore" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/fortum.svg" key="k16" alt="fortum" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />],

  [<img src="/img/logo-svg/gazoprojekt.svg" key="k17" alt="gazoprojekt" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/inter-ubezpieczenia.svg" key="k18" alt="inter ubezpieczenia" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/intrum.svg" key="k19" alt="intrum" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/kd.svg" key="k20" alt="kd" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/kghm.svg" key="k21" alt="kghm" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/krause.svg" key="k22" alt="krause" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/lpgk.svg" key="k23" alt="lpgk" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/maco.svg" key="k24" alt="maco" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />],

  [<img src="/img/logo-svg/ocado.svg" key="k25" alt="ocado" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/pbi.svg" key="k26" alt="pbi" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/posco.svg" key="k27" alt="posco" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/powiat.svg" key="k28" alt="powiat" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/rawiplug.svg" key="k29" alt="rawiplug" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/rotomat.svg" key="k30" alt="rotomat" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/sa.svg" key="k31" alt="sa" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/sweco.svg" key="k32" alt="sweco" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />],

  [<img src="/img/logo-svg/telka.svg" key="k33" alt="telka" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/totalizator-sportowy.svg" key="k34" alt="totalizator" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/urtica.svg" key="k35" alt="urtica" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/vintage.svg" key="k36" alt="vintage" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/vw.svg" key="k37" alt="vw" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/wagony.svg" key="k38" alt="wagony" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/womak.svg" key="k39" alt="womak" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />,
  <img src="/img/logo-svg/zamek-topacz.svg" key="k40" alt="zamek topacz" onDragStart={handleDragStart} loading="lazy" className="sliderimg lazyload" />]

];

{/*  Partnerzy  */}
const items2 = [
  [<a href="https://posoczewki.pl" rel="noopener norefferer" target="_blank"><img src="/img/logo-partners/posoczewki.svg" key="1" alt="posoczewki" width="150px" onDragStart={handleDragStart} loading="lazy" className="sliderimg grayimg" /></a>,
  <a href="https://e-multicontent.pl" rel="noopener norefferer" target="_blank"><img src="/img/logo-partners/e-multicontent.svg" key="2" alt="e-multicontent" width="150px" onDragStart={handleDragStart} loading="lazy" className="sliderimg grayimg" /></a>,
  <a href="https://www.bekuplast.com/pl/" rel="noopener norefferer" target="_blank"><img src="/img/logo-partners/bekuplast.svg" key="4" alt="bekuplast" width="150px" onDragStart={handleDragStart} loading="lazy" className="sliderimg grayimg" /></a>,]
];

{/*  CLIENTS OPINIONS    */}
const authors = ['Marek Banach | KRAUSE Sp. z o.o.',
'Aleksandra Cukier | TOZ Schronisko Wrocław',
'Łukasz Maślanka | TBS Wrocław Sp. z o.o.',]
const texts = ['A company with ideas, creative graphic designers, unusual designs, ecological products, calendars with stone paper, waterproof, biodegradable. Gadgets made of wheat, bamboo, straw, coffee, interesting design, good communication, quick action and short turnaround. A company completely worth recommending',
'I fully recommend using the services of Art Open :) A professional project prepared in accordance with the instructions, quick realization and a very quick, pleasant communication with employees during the implementation. I recommend ArtOpen to everyone who appreciates high quality!',
'I appreciate cooperation with the Art Open company - professionals work there, carry out orders on time, and show commitment and ingenuity during the implementation of commissioned works. After clicking on the selected box, you will be directed to the form with questions regarding the selected inquiry. I recommend!']


let i=0;

function changeTestplus() {
    if (i < 2){
    i++;
  } else {
    i = 0;
  }
  document.getElementById('txtchange').innerHTML = texts[i];
  document.getElementById('autor').innerHTML = authors[i];

}

function changeTestminus() {
    if (i > 0){
    i--;
  } else {
    i = 2;
  }
  document.getElementById('txtchange').innerHTML = texts[i];
  document.getElementById('autor').innerHTML = authors[i];

}


{/*  TIMERS    */}

function countDown() {
//initial numbers
let x = 0;
let x1 = 0;
let x2 = 0;
let x3 = 0;


var a =  setInterval( function(){

    if (document.getElementById('t1')!=null){
        if (x<12){
          x++;
          document.getElementById('t1').innerHTML = x;
        } else if (x===12){
          clearInterval(a);
        }
        }else {
          clearInterval(a);
        }
      }, 1500);


var b =  setInterval( function(){

    if (document.getElementById('t2')!=null){
        if (x1<250){
          x1++;
          document.getElementById('t2').innerHTML = x1;
        } else if (x1===250){
          clearInterval(b);
        }
        }else {
          clearInterval(b);
        }
      }, 100);

var c =  setInterval( function(){

    if (document.getElementById('t3')!=null){
        if (x2<5000){
          x2++;
          document.getElementById('t3').innerHTML = x2;
        } else if (x2===5000){
          clearInterval(c);
        }
        }else {
          clearInterval(c);
        }
      }, 10);

var d =  setInterval( function(){

    if (document.getElementById('t4')!=null){
        if (x3<12500){
          x3++;
          document.getElementById('t4').innerHTML = x3;
        } else if (x3===12500){
          clearInterval(d);
        }
        }else {
          clearInterval(d);
        }
      }, 1);

}




HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  keywords: PropTypes.array
}


export default HomePageTemplate
